<template>
  <div class="footerMobile">
    <div class="footerMobileTop">
      <img
        :src="require('../../../assets/logo.png')"
        class="img_logo_svg"
      />
      <div class="normalDiv" @click="jump('/index.html')">
        <span class="normalFont">首页</span>
        <img :src="this.arrowRight" class="normalImg" />
      </div>
      <div class="normalDiv" @click="jump('/app.html')">
        <span class="normalFont">应用</span>
        <img :src="this.arrowRight" class="normalImg" />
      </div>
      <div class="normalDiv" @click="jump('/about.html')">
        <span class="normalFont">关于我们</span>
        <img :src="this.arrowRight" class="normalImg" />
      </div>
      <div class="normalDiv" @click="jump('/develop.html')">
        <span class="normalFont">员工发展</span>
        <img :src="this.arrowRight" class="normalImg" />
      </div>
      <div class="normalDiv" @click="jump('/hire.html')">
        <span class="normalFont">加入我们</span>
        <img :src="this.arrowRight" class="normalImg" />
      </div>
      <div class="footerTopInformation">
        <div class="talk">商务洽谈<br />yitaihaoxi@163.com</div>
        <div class="talk">合作推广<br />yitaihaoxi@163.com</div>
        <div class="talk">加入我们<br />yitaihaoxi@163.com</div>
      </div>
    </div>
    <div class="footerMobileBottom">
      <div class="footerMobileBottomFont">
        粤ICP备2021047232号-1 ICP经营许可证：粤B2-20200925 <br />
        天津一台好戏文化传媒有限公司 版权所有<br />
        Copyright © 2021 Gozean. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Config from 'utils/Config';
// let arrowRight = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABCUlEQVRoQ+2XMQ7CMBAE9/IQJKJUPISWjofyAhoKesrkE/mAUSQqisixvBuwNvXd2TMbxXGgkSca4YBBfi1JJ+JESAb8apHEFo91IsXqSI1OhCS2eKwTKVZHanQiJLHFY6smklKKYRgeAOaIuI7jOBfvbGMjA+SVUjoBeHZdd1bBVAVZJPZ9fwBwB3BUwlQH2QuGArIHDA1EDUMFUcLQQVQwEpBvmIi4TdN02XhUrJYbZItNxdlCT0QBsUilgqggqCBKCBqIGoICsgdEdZDPfeT/f+ObuVhtOVtq11I/v7U3uzbPIErbOWs5kRxLyhonorSds5YTybGkrHEiSts5azmRHEvKmmYSeQOaIXwzBcfFEgAAAABJRU5ErkJggg=='
export default {
  name: 'footerMobile',
  data() {
    return {
      arrowRight:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABCUlEQVRoQ+2XMQ7CMBAE9/IQJKJUPISWjofyAhoKesrkE/mAUSQqisixvBuwNvXd2TMbxXGgkSca4YBBfi1JJ+JESAb8apHEFo91IsXqSI1OhCS2eKwTKVZHanQiJLHFY6smklKKYRgeAOaIuI7jOBfvbGMjA+SVUjoBeHZdd1bBVAVZJPZ9fwBwB3BUwlQH2QuGArIHDA1EDUMFUcLQQVQwEpBvmIi4TdN02XhUrJYbZItNxdlCT0QBsUilgqggqCBKCBqIGoICsgdEdZDPfeT/f+ObuVhtOVtq11I/v7U3uzbPIErbOWs5kRxLyhonorSds5YTybGkrHEiSts5azmRHEvKmmYSeQOaIXwzBcfFEgAAAABJRU5ErkJggg=='
    };
  },
  methods: {
    jump(url) {
      window.location.href = `${Config.domain}${url}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.footerMobile {
  position: relative;
  // height: 94px;
  width: 100%;
  z-index: 2;
}
.footerMobileTop {
  // height: 1628px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px 0 32px 20px;
}
.img_logo_svg {
  width: 98px;
  margin-bottom: 28px;
}
.normalDiv {
  width: 94%;
  height: 56px;
  background: #ffffff;
  // box-shadow: 0px -2px 0px 0px rgba(225,225,225,1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f6fafe;
}
.normalFont {
  // height: 84px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}
.normalImg {
  height: 24px;
  width: 24px;
}
.footerTopInformation {
  width: 90%;
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}
.talk {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
}
.footerMobileBottom {
  // height: 361px;
  width: 100%;
  background: #f6fafe;
  box-sizing: border-box;
  padding: 20px 0 20px 20px;
}
.footerMobileBottomFont {
  width: 301px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #888888;
  line-height: 18px;
}

// @media screen and (min-width: 550px) and (max-width: 768px) {
//   .normalFont{
//     font-size: 19px !important;
//   }
//   .normalDiv{
//     height: 75px;
//   }
//   .talk{
//     font-size: 16px;
//   }
//   .footerMobileBottomFont{
//     font-size: 15px;
//     padding: 27px 0;
//   }
// }
</style>
